import { createStyles, makeStyles } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import '../assets/sass/new-age.scss';

const useStyles = makeStyles(() =>
  createStyles({
    top: {
      position: 'relative',
      minHeight: '100vh',
    },
    buttonWrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      width: 180,
    },
  })
);

const Layout = ({ children, titlePrefixes = [] }) => {
  const styles = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            title={[...titlePrefixes, data.site.siteMetadata.title]
              .filter((t) => t)
              .join(' - ')}
            meta={[
              {
                name: 'description',
                content:
                  'The complete platform for indoor training: Row, Ski, Bike. Connect your Concept2, WaterRower, SmartRow, Rogue Echo, FTMS, and more.',
              },
              {
                name: 'keywords',
                content:
                  'concept2,rp3,waterrower,smartrow,rogue,echo,ftms,app,c2,rower,rowerg,skierg,bikeerg,ergathlon,multierg,indoor rowing,erg,coach,remote,coaching,gym,studio,logbook,workout,competition,wod,heart rate monitor',
              },
              { name: 'apple-itunes-app', content: 'app-id=1474566270' },
              {
                name: 'facebook-domain-verification',
                content: 'dpsqe8r6cmr8vveczvapd0knvec20v',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <div className={styles.top}>{children}</div>
          <CookieConsent
            buttonWrapperClasses={styles.buttonWrapper}
            buttonClasses={styles.button}
          >
            This website uses cookies to ensure you get the best user
            experience. <a href="/privacy">Learn more</a>
          </CookieConsent>
        </>
      )}
    />
  );
};

export default Layout;
